<script setup lang="ts">
import SignBlock from './SignBlock.ce.vue'

const { isPrintMode } = storeToRefs(useProtocol())

const item = ref({
  revDate1: '',
  revDesc1: '',
  revName1: '',
  revDate2: '',
  revDesc2: '',
  revName2: '',
  internalProject: '',
  customerProject: '',
  internalProjectNumber: '',
  customerProjectNumber: '',
  machineType: '',
  serialName1: '',
  serialName2: '',
  serialNumber1: '',
  serialNumber2: '',
  softVersion: '',
  name1: 'Name',
  name2: 'Name',
})
const selectedOption = ref('')

function selectOption(option) {
  if (selectedOption.value === option) {
    selectedOption.value = ''
  } else {
    selectedOption.value = option
  }
}
</script>

<template>
  <div
    :class="
      isPrintMode ? 'margin-page print-mode' : 'margin-page'
    "
  >
    <h4 class="title">REVISION HISTORY</h4>
    <div class="spacer"></div>

    <table class="table">
      <thead>
        <tr>
          <th>Rev.</th>
          <th>Date</th>
          <th>Description of changes</th>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="centered-element">01</td>
          <td contenteditable="true">
            {{ item.revDate1 }}
          </td>
          <td contenteditable="true">
            {{ item.revDesc1 }}
          </td>
          <td contenteditable="true">
            {{ item.revName1 }}
          </td>
        </tr>
        <tr>
          <td class="centered-element">02</td>
          <td contenteditable="true">
            {{ item.revDate2 }}
          </td>
          <td contenteditable="true">
            {{ item.revDesc2 }}
          </td>
          <td contenteditable="true">
            {{ item.revName2 }}
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
    <div class="spacer"></div>

    <table class="table no-top-border">
      <thead>
        <tr>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td></td>
          <td>Internal</td>
          <td>Customer</td>
        </tr>
        <tr>
          <td>Project:</td>
          <td contenteditable="true">
            {{ item.internalProject }}
          </td>
          <td contenteditable="true">
            {{ item.customerProject }}
          </td>
        </tr>
        <tr>
          <td>Project number:</td>
          <td contenteditable="true">
            {{ item.internalProjectNumber }}
          </td>

          <td contenteditable="true">
            {{ item.customerProjectNumber }}
          </td>
        </tr>
        <tr>
          <td>Machine type</td>
          <td contenteditable="true">
            {{ item.machineType }}
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
    <table class="table no-top-border">
      <thead>
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td></td>
          <td contenteditable="true">
            {{ item.serialName1 }}
          </td>
          <td contenteditable="true">
            {{ item.serialName1 }}
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Serial number:</td>
          <td contenteditable="true">
            {{ item.serialNumber1 }}
          </td>
          <td contenteditable="true">
            {{ item.serialNumber2 }}
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Software version:</td>
          <td contenteditable="true" colspan="4">
            {{ item.softVersion }}
          </td>
        </tr>
        <tr>
          <td></td>
          <td colspan="4"></td>
        </tr>
      </tbody>
    </table>
    <div class="spacer"></div>

    <table class="table no-borders">
      <thead>
        <tr>
          <th class="th-min"></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Tested:</td>
          <td>
            <label>
              <input
                type="checkbox"
                :checked="selectedOption === 'successful'"
                @change="selectOption('successful')"
              />
              Successful
            </label>
          </td>
          <td>
            <label>
              <input
                type="checkbox"
                :checked="
                  selectedOption === 'not_successful'
                "
                @change="selectOption('not_successful')"
              />
              Not successful
            </label>
          </td>
        </tr>
        <tr>
          <td></td>
          <td>
            <label>
              <input
                type="checkbox"
                :checked="
                  selectedOption ===
                  'successful_with_restrictions'
                "
                @change="
                  selectOption(
                    'successful_with_restrictions',
                  )
                "
              />
              Successful with restrictions
            </label>
          </td>
          <td>Number of restrictions:</td>
        </tr>
        <tr>
          <td>Date:</td>
          <td colspan="2" class="signature-padding">
            <div class="signature-line">
              <div class="line-part">
                <span>Signature</span>
                <SignBlock class="underline" />
              </div>
              <div class="line-part">
                <span>Name</span>
                <div
                  contenteditable="true"
                  class="input-part"
                >
                  {{ item.name1 }}
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>Tested / Verified:</td>
          <td colspan="2"></td>
        </tr>
        <tr>
          <td>Date:</td>
          <td colspan="2" class="signature-padding">
            <div class="signature-line">
              <div class="line-part">
                <span>Signature</span>
                <SignBlock class="underline" />
              </div>
              <div class="line-part">
                <span>Name</span>
                <div
                  contenteditable="true"
                  class="input-part"
                >
                  {{ item.name2 }}
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <div class="spacer"></div>

    <div class="footer">
      <span class="italic-text"
        >GPin Test Protocol Rev#1</span
      >
      <div class="footer-flex">
        <p>
          Copyright AuTeBa GmbH Ludwig-Erhard-Straße 4 34131
          Kassel - Confidential document - Printed documents
          are not subject to change service.
        </p>
        <p>Page 1 from 2</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
@import '@/assets/scss/protocol.scss';
</style>
