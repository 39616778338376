<script setup lang="ts">
import SignaturePad from 'signature_pad'

const { isPrintMode } = storeToRefs(useProtocol())

const canvas = ref<HTMLCanvasElement | null>(null)
let signaturePad: SignaturePad | null = null

function resizeCanvas() {
  if (canvas.value) {
    const ratio = Math.max(window.devicePixelRatio || 1, 1)
    canvas.value.width = canvas.value.offsetWidth * ratio
    canvas.value.height = canvas.value.offsetHeight * ratio
    canvas.value.getContext('2d')?.scale(ratio, ratio)

    signaturePad?.clear()
  }
}

function adjustEventCoordinates(
  event: MouseEvent | TouchEvent,
) {
  if (!canvas.value) return { x: 0, y: 0 }

  const rect = canvas.value.getBoundingClientRect()
  const scaleX = canvas.value.width / rect.width
  const scaleY = canvas.value.height / rect.height

  if (event instanceof MouseEvent) {
    return {
      x: (event.clientX - rect.left) * scaleX,
      y: (event.clientY - rect.top) * scaleY,
    }
  } else if (
    event instanceof TouchEvent &&
    event.touches[0]
  ) {
    return {
      x: (event.touches[0].clientX - rect.left) * scaleX,
      y: (event.touches[0].clientY - rect.top) * scaleY,
    }
  }

  return { x: 0, y: 0 }
}

onMounted(() => {
  if (canvas.value) {
    signaturePad = new SignaturePad(canvas.value, {
      backgroundColor: 'rgb(255, 255, 255)',
    })

    resizeCanvas()
    window.addEventListener('resize', resizeCanvas)

    const originalOnBegin = signaturePad.onBegin
    signaturePad.onBegin = function () {
      const coords = adjustEventCoordinates(
        event as MouseEvent | TouchEvent,
      )
      originalOnBegin?.call(this)
    }
  }
})

onUnmounted(() => {
  window.removeEventListener('resize', resizeCanvas)
})

function clearSignature() {
  signaturePad?.clear()
}
</script>

<template>
  <div class="signature-pad">
    <div
      :class="
        isPrintMode
          ? 'signature-pad--body print-mode'
          : 'signature-pad--body'
      "
    >
      <canvas ref="canvas"></canvas>
    </div>
    <div class="signature-pad--footer hide-in-print">
      <button
        type="button"
        :class="
          isPrintMode
            ? 'button clear print-mode'
            : 'button clear'
        "
        @click="clearSignature"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 14 14"
        >
          <path
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M11.5 8.5h-9l-.76 3.8a1 1 0 0 0 .21.83a1 1 0 0 0 .77.37h8.56a1.002 1.002 0 0 0 .77-.37a1.001 1.001 0 0 0 .21-.83zm0-3a1 1 0 0 1 1 1v2h-11v-2a1 1 0 0 1 1-1H4a1 1 0 0 0 1-1v-2a2 2 0 1 1 4 0v2a1 1 0 0 0 1 1zm-3 8V11"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<style scoped>
.signature-pad {
  display: flex;
  gap: 10px;
  width: 100%;
  padding: 10px;
}

.signature-pad--body {
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 230px;
  height: 70px;
}

canvas {
  width: 100%;
  height: 100%;
}

.button.clear {
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
  display: grid;
  place-items: center;
  padding: 8px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: none;
  cursor: pointer;
}
.print-mode.signature-pad--body {
  border: 0px;
}
.print-mode.button.clear {
  display: none;
}
</style>
