<script setup lang="ts">
const { isPrintMode } = storeToRefs(useProtocol())
const { protocol } = storeToRefs(useProtocol())

const tableData = ref([
  {
    no: 1,
    testItem: '',
    acceptanceCriteria: '',
    requirementsMet: { yes: false, no: false },
    comment: '',
    reference: '',
    examiner: '',
    reviewer: '',
  },
  {
    no: 2,
    testItem: '',
    acceptanceCriteria: '',
    requirementsMet: { yes: false, no: false },
    comment: '',
    reference: '',
    examiner: '',
    reviewer: '',
  },
  {
    no: 3,
    testItem: '',
    acceptanceCriteria: '',
    requirementsMet: { yes: false, no: false },
    comment: '',
    reference: '',
    examiner: '',
    reviewer: '',
  },
  {
    no: 4,
    testItem: '',
    acceptanceCriteria: '',
    requirementsMet: { yes: false, no: false },
    comment: '',
    reference: '',
    examiner: '',
    reviewer: '',
  },
  {
    no: 5,
    testItem: '',
    acceptanceCriteria: '',
    requirementsMet: { yes: false, no: false },
    comment: '',
    reference: '',
    examiner: '',
    reviewer: '',
  },
  {
    no: 6,
    testItem: '',
    acceptanceCriteria: '',
    requirementsMet: { yes: false, no: false },
    comment: '',
    reference: '',
    examiner: '',
    reviewer: '',
  },
  {
    no: 7,
    testItem: '',
    acceptanceCriteria: '',
    requirementsMet: { yes: false, no: false },
    comment: '',
    reference: '',
    examiner: '',
    reviewer: '',
  },
  {
    no: 8,
    testItem: '',
    acceptanceCriteria: '',
    requirementsMet: { yes: false, no: false },
    comment: '',
    reference: '',
    examiner: '',
    reviewer: '',
  },
  {
    no: 9,
    testItem: '',
    acceptanceCriteria: '',
    requirementsMet: { yes: false, no: false },
    comment: '',
    reference: '',
    examiner: '',
    reviewer: '',
  },
  {
    no: 10,
    testItem: '',
    acceptanceCriteria: '',
    requirementsMet: { yes: false, no: false },
    comment: '',
    reference: '',
    examiner: '',
    reviewer: '',
  },
])
</script>

<template>
  <div
    :class="
      isPrintMode
        ? 'margin-page-a3 print-mode'
        : 'margin-page-a3'
    "
  >
    <div class="header">
      <div class="header__centered-text">
        {{ protocol?.title }}
      </div>
    </div>

    <div class="spacer"></div>
    <h4 class="title">4. Tests - minimum test criteria</h4>
    <div class="spacer"></div>

    <table class="table-tests">
      <thead>
        <tr>
          <th>No.</th>
          <th>Test item</th>
          <th>Acceptance criteria Available?</th>
          <th class="th-min">
            Test specification / test note
          </th>
          <th colspan="2">Requirements met Yes / No</th>
          <th>Comment</th>
          <th>Reference (document name)</th>
          <th>Date / Signature Examiner</th>
          <th>Date / Signature Reviewer</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in tableData"
          :key="item.no"
        >
          <td class="centered-element">{{ item.no }}.</td>
          <td
            v-if="index == 0"
            rowspan="10"
            class="centered-element no-border"
          >
            Documentation
          </td>

          <td contenteditable="true">
            {{ item.testItem }}
          </td>
          <td contenteditable="true">
            {{ item.acceptanceCriteria }}
          </td>
          <td class="checkboxes-element">
            <input
              type="checkbox"
              :checked="item.requirementsMet === true"
              @change="item.requirementsMet = true"
            />
          </td>
          <td class="checkboxes-element">
            <input
              type="checkbox"
              :checked="item.requirementsMet === false"
              @change="item.requirementsMet = false"
            />
          </td>
          <td
            :contenteditable="
              item.requirementsMet ? 'false' : 'true'
            "
          >
            {{ item.comment }}
          </td>
          <td
            :contenteditable="
              item.requirementsMet ? 'false' : 'true'
            "
          >
            {{ item.reference }}
          </td>
          <td
            :contenteditable="
              item.requirementsMet ? 'false' : 'true'
            "
          >
            {{ item.examiner }}
          </td>
          <td
            :contenteditable="
              item.requirementsMet ? 'false' : 'true'
            "
          >
            {{ item.reviewer }}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="spacer"></div>
    <div class="footer">
      <span class="italic-text"
        >GPin Test Protocol Rev#1</span
      >
      <div class="footer-flex">
        <p>
          Copyright AuTeBa GmbH Ludwig-Erhard-Straße 4 34131
          Kassel - Confidential document - Printed documents
          are not subject to change service.
        </p>
        <p>Page 2 from 2</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
@import '@/assets/scss/protocol.scss';

th:nth-child(1) {
  width: 5%;
}

.table-tests th,
.table-tests td {
  border: 1px solid rgba(0, 0, 0, 1);
  padding: 8px 3px;
}
.table-tests td {
  width: 30px;
  height: 45px;
  overflow: hidden;
  white-space: nowrap;
}
.table-tests td:nth-child(3) {
  width: 200px;
}
.table-tests thead {
  background-color: #cfcfcf;
}
</style>
