<script setup lang="ts">
const { isPrintMode, isUploadedImg } =
  storeToRefs(useProtocol())

const uploadedImage = ref<string | null>(null)

const handleFileUpload = (event: Event) => {
  const file = (event.target as HTMLInputElement).files?.[0]
  if (file) {
    const reader = new FileReader()
    reader.onload = () => {
      uploadedImage.value = reader.result as string
    }
    reader.readAsDataURL(file)
    isUploadedImg.value = true
  }
}

const clearImage = () => {
  uploadedImage.value = null
  isUploadedImg.value = false
}
</script>

<template>
  <div
    :class="
      isPrintMode ? 'margin-page print-mode' : 'margin-page'
    "
  >
    <h3 v-if="!isPrintMode">Attach image (optional)</h3>
    <input
      type="file"
      @change="handleFileUpload"
      accept="image/*"
    />

    <div v-if="uploadedImage">
      <img :src="uploadedImage" alt="Uploaded Image" />
      <button @click="clearImage">Clear Image</button>
    </div>
  </div>
</template>

<style scoped>
@import '@/assets/scss/protocol.scss';
h3 {
  margin-bottom: 10px;
}
input {
  margin-bottom: 15px;
}
img {
  max-width: 700px;
  max-height: 650px;
}
button {
  display: block;
}
.print-mode input,
.print-mode button {
  display: none;
}
</style>
