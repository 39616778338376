<script setup lang="ts">
import VueFileToolbarMenu from 'vue-file-toolbar-menu'
import VueDocumentEditor from '~/components/Protocol/CustomEditor/CustomEditor.vue'
import Page1 from '~/components/Protocol/Page1_Revision.ce.vue'
import Page2 from '~/components/Protocol/Page2_Tests.ce.vue'
import Page3 from '~/components/Protocol/Page3_UploadImg.ce.vue'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'

const { isPrintMode, isUploadedImg, protocol, loading } =
  storeToRefs(useProtocol())

const { uuid } = useRoute().params
const toast = useToast()
const folder = ref(null)

onMounted(async () => {
  folder.value = await useFolders().getFolderByUuid(uuid)
  if (!folder.value) {
    toast.add({
      severity: 'error',
      summary: 'Error during company data loading.',
      detail: `Details: folder with such uuid not found`,
    })
  } else {
    await useProtocol().getProtocolByFolderId(
      folder.value.id,
    )
  }
})

const zoom = ref(0.8)
const zoom_min = 0.1
const zoom_max = 5.0
const display = ref('vertical')
const page_margins = '0mm 0mm'
const page_format_mm = [
  [210, 297],
  [297, 220],
  [297, 220],
]

let start_zoom_gesture = false
let start_dist_touch = false
let start_zoom_touch = false

const content = ref([
  {
    template: markRaw(Page1),
  },
  {
    template: markRaw(Page2),
  },
  {
    template: markRaw(Page3),
  },
])

const menu = computed(() => [
  {
    text: 'Save & Send',
    title: 'Save & Send',
    icon: 'save',
    click: async () => {
      await savePDF()
    },
  },

  { is: 'spacer' },
  {
    text: Math.floor(zoom.value * 100) + '%',
    title: 'Zoom',
    icon: 'zoom_in',
    chevron: true,
    menu: [
      ['200%', 2.0],
      ['150%', 1.5],
      ['125%', 1.25],
      ['100%', 1.0],
      ['75%', 0.75],
      ['50%', 0.5],
      ['25%', 0.25],
    ].map(([text, value]) => ({
      text,
      active: zoom.value === value,
      click: () => {
        zoom.value = value
      },
    })),
    menu_width: 80,
    menu_height: 280,
    menu_class: 'align-center',
  },
])

const handleWheel = (e: WheelEvent) => {
  if (e.ctrlKey) {
    e.preventDefault()
    zoom.value = Math.min(
      Math.max(zoom.value - e.deltaY * 0.01, zoom_min),
      zoom_max,
    )
  }
}

const handleGestureStart = (e: Event) => {
  e.preventDefault()
  start_zoom_gesture = zoom.value
}

const handleGestureChange = (e: any) => {
  e.preventDefault()
  if (!start_zoom_touch) {
    zoom.value = Math.min(
      Math.max(start_zoom_gesture * e.scale, zoom_min),
      zoom_max,
    )
  }
}

const handleGestureEnd = () => {
  start_zoom_gesture = false
}

const handleTouchStart = (e: TouchEvent) => {
  if (e.touches.length === 2) {
    e.preventDefault()
    start_dist_touch = Math.hypot(
      e.touches[0].pageX - e.touches[1].pageX,
      e.touches[0].pageY - e.touches[1].pageY,
    )
    start_zoom_touch = zoom.value
  }
}
const handleTouchMove = (e: TouchEvent) => {
  if (start_dist_touch && start_zoom_touch) {
    e.preventDefault()
    const newZoom =
      (start_zoom_touch *
        Math.hypot(
          e.touches[0].pageX - e.touches[1].pageX,
          e.touches[0].pageY - e.touches[1].pageY,
        )) /
      start_dist_touch
    zoom.value = Math.min(
      Math.max(newZoom, zoom_min),
      zoom_max,
    )
  }
}
const handleTouchEnd = () => {
  start_dist_touch = false
  start_zoom_touch = false
}

const savePDF = async () => {
  const style = document.createElement('style')
  style.innerHTML = `
    html {
      line-height: 0.5 !important;
    }
    body {
      font-family: Arial, sans-serif;
      font-size: 12px;
    }
  `

  document.head.appendChild(style)

  try {
    await useProtocol().changePrintMode()
    window.dispatchEvent(new Event('beforeprint'))

    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
    })
    const pages = document.querySelectorAll('.page')
    const pdfWidth = pdf.internal.pageSize.getWidth()
    let firstPage = true

    const pageCount = isUploadedImg.value
      ? pages.length
      : pages.length - 1

    for (let i = 0; i < pageCount; i++) {
      const page = pages[i]
      const canvas = await html2canvas(page, {
        scale: 3,
      })
      const imgData = canvas.toDataURL('image/png')

      if (i >= 1) {
        pdf.addPage([297, 220], 'landscape')
        const pdfWidthA3 = pdf.internal.pageSize.getWidth()
        const pdfHeightA3 =
          (canvas.height * pdfWidthA3) / canvas.width
        pdf.addImage(
          imgData,
          'PNG',
          0,
          0,
          pdfWidthA3,
          pdfHeightA3,
          undefined,
          'SLOW',
        )
      } else {
        const pdfHeight =
          (canvas.height * pdfWidth) / canvas.width
        if (!firstPage) {
          pdf.addPage()
        }
        pdf.addImage(
          imgData,
          'PNG',
          0,
          0,
          pdfWidth,
          pdfHeight,
          undefined,
          'SLOW',
        )
      }

      firstPage = false
    }

    // pdf.save('multi-page.pdf')

    const pdfBase64 = pdf.output('datauristring')

    await useProtocol().saveProtocol({
      ...protocol.value,
      content: pdfBase64.split(',')[1],
    })

    toast.add({
      severity: 'success',
      summary: 'Document successfully saved and sent',
      life: 2000,
    })
  } catch (e: any) {
    toast.add({
      severity: 'error',
      summary: 'Error during sending.',
      detail: e.message,
    })
  } finally {
    document.head.removeChild(style)
    useProtocol().changePrintMode()

    window.dispatchEvent(new Event('afterprint'))
  }
}

onMounted(() => {
  window.addEventListener('wheel', handleWheel, {
    passive: false,
  })
  window.addEventListener(
    'gesturestart',
    handleGestureStart,
  )
  window.addEventListener(
    'gesturechange',
    handleGestureChange,
  )
  window.addEventListener('gestureend', handleGestureEnd)
  window.addEventListener('touchstart', handleTouchStart, {
    passive: false,
  })
  window.addEventListener('touchmove', handleTouchMove, {
    passive: false,
  })
  window.addEventListener('touchend', handleTouchEnd, {
    passive: false,
  })
})

onBeforeUnmount(() => {
  window.removeEventListener('wheel', handleWheel)
  window.removeEventListener(
    'gesturestart',
    handleGestureStart,
  )
  window.removeEventListener(
    'gesturechange',
    handleGestureChange,
  )
  window.removeEventListener('gestureend', handleGestureEnd)
  window.removeEventListener('touchstart', handleTouchStart)
  window.removeEventListener('touchmove', handleTouchMove)
  window.removeEventListener('touchend', handleTouchEnd)
})
</script>

<template>
  <div class="main">
    <Toast :unstyled="true" />

    <div v-if="protocol">
      <vue-file-toolbar-menu :content="menu" class="bar" />

      <vue-document-editor
        class="editor"
        ref="editor"
        v-model:content="content"
        :zoom="zoom"
        :page_format_mm="page_format_mm"
        :page_margins="page_margins"
        :display="display"
      />
    </div>
    <div
      v-else
      class="flex justify-center items-center h-screen"
    >
      <div v-if="loading" class="spinner" />
      <p v-else>Protocol is not configured.</p>
    </div>
  </div>
</template>

<style scoped>
::-webkit-scrollbar-track,
::-webkit-scrollbar-corner {
  display: none;
}
.main {
  padding-top: 50px;
}
.bar {
  position: fixed;
  position: -webkit-sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background: rgba(248, 249, 250, 0.8);
  border-bottom: solid 1px rgb(248, 249, 250);
  backdrop-filter: blur(10px);
  --bar-button-active-color: #188038;
  --bar-button-open-color: #188038;
  --bar-button-active-bkg: #e6f4ea;
  --bar-button-open-bkg: #e6f4ea;
}
</style>
